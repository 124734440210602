import axios from 'axios'

const $http = axios.create({
    baseURL: process.env.REACT_APP_API_BASE,
    crossDomain: true,
    headers: {
       'X-Requested-With': 'XMLHttpRequest',
       'Content-Type': 'application/json',
    //    'Authorization': `Bearer ${localStorage.getItem('token')}`
    }
 })
 $http.interceptors.request.use((config) => {
     config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`
     return config;
 }, function(err) {
    return Promise.reject(err);
  });

 $http.interceptors.response.use(null, (event) => {
     if(event.response && event.response.status === 401 && window.location.hash !== '#/login'){
         localStorage.removeItem('token')
         window.location.hash = '#/login'
        return event;
     }
     return event;
 })
 export {
    $http
 };