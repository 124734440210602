import React from 'react'
import {Navbar,Nav,NavDropdown} from 'react-bootstrap'
import Logo from './Logo'

import { useHistory, Link } from "react-router-dom";

const Header = props => {
    
    let history = useHistory();

    function logOut(){

        localStorage.removeItem('token')
        history.push('/login')

    }

    return (
    <>
        <Navbar  className="text-white" bg="light">
            <Navbar.Brand  className="text-white">
                <Link className="text-white" to="/"><Logo style={{width:100}}></Logo></Link>
            </Navbar.Brand>
            <Navbar.Collapse className="justify-content-end">
                <Nav>
                    <NavDropdown title="Pedido">
                        <NavDropdown.Item>
                            <Link to="/">Lista</Link>
                        </NavDropdown.Item>
                        <NavDropdown.Item>
                            <Link to="/pedidos/novo">Novo pedido</Link>
                        </NavDropdown.Item>
                    </NavDropdown>
                </Nav>
                <Nav.Link onClick={logOut}>Sair</Nav.Link>
            </Navbar.Collapse>
        </Navbar>
    </>
    )

}

export default Header;