import React, {useCallback,useState,useEffect} from 'react'
import Header from './Header'

import {
    ListGroup,
    Card,
    Container,
    InputGroup,FormControl,Pagination
} from 'react-bootstrap'
import {
    Link
} from "react-router-dom";

import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import { faEdit,faKeyboard,faTimes } from '@fortawesome/free-solid-svg-icons'

import {
    $http
} from '../helpers'

import { debounce, get } from "lodash";

const Pedidos = (props) => {

    const [state,setState] = useState({
        pedidos: {
            data: []
        },
        loading: false,
        inputSearch: '',
        page: 1
    })

    const [pagination,setPagination] = useState({
        pagina_atual: 1
    })

    const search = async (search) => {

        setState({
            ...state,
            inputSearch: search.s,
            p: search.p,
            loading:true
        })

        const result = await $http.get('pedidos',{
            params:{
                s: search.search,
                page: search.page
            }
        })
        if(result.status !== 200) return;
        
        setState({
            ...state,
            inputSearch: search.s,
            p: search.p,
            loading:false,
            pedidos: result.data
        })

        setPagination({
            pagina_atual: result.data.meta.current_page
        })

    }

    useEffect(() => {
        search({
            search: '',
            page: 1
        })
    }, []);

    const onSearchHandle = useCallback(debounce((inputSearch) => {
        search({
            search: inputSearch,
            page: 1
        })
    }, 600), []);

    const onSearch = (inputSearch) => {
        setState({
            ...state,
            inputSearch: inputSearch
        })
        onSearchHandle(inputSearch)
    }

    const reset = () => {
        setState({
            ...state,
            inputSearch:''
        })
        search({
            search: null,
            page: 1
        })
    }

    const pedidos = () => {

        return state.pedidos.data.map((pedido,key) => {
            return <>
                <ListGroup.Item key={key}>
                        <Link to={`pedidos/${pedido.id}`} title="Editar" class="btn btn-sm btn-link">
                            <Icon size="sm" icon={faEdit}></Icon>
                        </Link> 
                        #{pedido.numero} - {pedido.cliente_nome}
                    </ListGroup.Item>
            </>
        })

    }

    return (
        <>
            <Header />
            <Container style={{marginTop:10}}>
            <InputGroup>
                    <InputGroup.Prepend>
                        <InputGroup.Text id="search-icon">
                            <Icon icon={faKeyboard}></Icon>
                        </InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl 
                        placeholder="Numero ou nome do cliente"
                        aria-label="Numero ou nome do cliente"
                        aria-describedby="search-icon"
                        onChange={(e) => onSearch(e.target.value)}
                        value={state.inputSearch}
                        size="lg"
                    ></FormControl>
                    <InputGroup.Prepend>
                        <InputGroup.Text id="search-reset" onClick={reset}>
                            <Icon icon={faTimes}></Icon>
                        </InputGroup.Text>
                    </InputGroup.Prepend>
                </InputGroup>

                <Card className="mt-2">
                    <Card.Header>Lista de pedidos</Card.Header>     

                    <ListGroup variant="flush">
                        {
                            state.loading ? 
                                <ListGroup.Item>Carregando pedidos...</ListGroup.Item>
                            : <>
                                { state.pedidos.data && state.pedidos.data.length > 0 
                                    ? pedidos()
                                    : <ListGroup.Item>Nenhum pedido cadastrado</ListGroup.Item>
                                }
                            </>
                        }
                    </ListGroup>
                </Card>
                {
                    !state.loading ?
                    
                    <div class="d-flex justify-content-end mt-2">
                        <Pagination size="lg">
                            <Pagination.Prev onClick={() => {
                                search({
                                    searh: state.inputSearch,
                                    page: pagination.pagina_atual > 1 ? pagination.pagina_atual - 1  : 1
                                })
                            }} />
                            <Pagination.Item active>{pagination.pagina_atual}</Pagination.Item>
                            <Pagination.Next onClick={() => {
                                search({
                                    searh: state.inputSearch,
                                    page: pagination.pagina_atual + 1
                                })
                            }} />
                        </Pagination>
                        </div>
                    : "" }
            </Container>
        </>
    );
};

export default Pedidos;