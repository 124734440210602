import React, {useState,useEffect} from 'react'
import Form from './FormPedido'
import Header from '../Header'

import { useParams } from "react-router-dom";

import {$http} from '../../helpers'
import {loading,warning,success} from '../../helpers/alerts'
import { useHistory } from "react-router-dom";
import { format,isValid } from 'date-fns'
import moment from 'moment'

import {
    Card,
    Container
} from 'react-bootstrap'

const Editar = props => {

    const [state,setState] = useState({
        loading: false
    })
    const [pedido,setPedido] = useState({
        cliente_nome: '',
        numero: '',
        status: 'ARTE_CRIANDO',
        previsao_entrega: '',
        data_entrega: '',
        codigo_rastreio: '',
    })

    let params = useParams();
    let history = useHistory();

    const findPedido = async () => {

        setState({loading:true})

        let result = await $http.get(`pedidos/${params.pedido_id}`)

        if(result.status !== 200){
            return;
        }

        let data = result.data.data
        data.data_entrega = data.data_entrega ? moment(data.data_entrega,'YYYY-MM-DD').toDate() : null

        setPedido(data)
        setState({loading:false})

    }

    const save = async (data) => {
        setPedido(data)

        try{

            loading('show')

            let result = await $http.put(`pedidos/${params.pedido_id}`,{
                ...data,
                data_entrega: isValid(data.data_entrega) ? format(data.data_entrega,'yyyy-MM-dd') : null
            })

            if(result.status !== 200) throw result.data

            await success({
                title: 'Salvo com sucesso'
            })

            history.push('/')

        }catch(e){
            warning({
                title:'Houve alguma divergência',
                message: e
            })
        }
    }

    // quando o component é montado
    useEffect(() => {
        findPedido()
    },[])

    const remover = async () => {

        loading()
        await $http.delete(`pedidos/${params.pedido_id}`)
        await success({
            title: "Removido"
        })
        history.push("/")

    }

    return (<>
        <Header />
        <Container style={{marginTop:10}}>
            <Card>
    <Card.Header>Editar pedido</Card.Header>
                <Card.Body>
                    {!state.loading ?
                    <Form data={pedido} onSave={save} drop onRemover={remover} />
                    :
                    'Carregando'
                    }
                </Card.Body>
            </Card>
        </Container>
    </>);
}

export default Editar;