import React,{useState} from 'react'
import { useForm } from "react-hook-form";

import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'

import { 
    Button,
    Row,
    Col
} from 'react-bootstrap'


import {
    Form,
} from 'react-bootstrap'


import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";


const FormCategoria = props => {

    const { register, handleSubmit, errors } = useForm();
    const [ state, setState ] = useState({
        cliente_nome: props.data.cliente_nome,
        numero: props.data.numero,
        status: props.data.status,
        previsao_entrega: props.data.previsao_entrega,
        data_entrega: props.data.data_entrega,
        codigo_rastreio: props.data.codigo_rastreio,
    })

    const setPedido = (value) => {
        setState({ 
            ...state,
            ...value
        })
    }

    
    return <>
        <Form onSubmit={handleSubmit(() => {
            props.onSave(state)
        })}>
            <Form.Group>
                <Form.Label>Cliente</Form.Label>
                <Form.Control 
                    value={state.cliente_nome} 
                    onChange={ e => {
                        e.preventDefault()
                        setPedido({cliente_nome: e.target.value}) 
                    }}

                    name="cliente_nome"
                    isInvalid={errors.cliente_nome}
                    ref={register({required:true})}
                    >
                </Form.Control>
            </Form.Group>
            <Form.Group>
                <Form.Label>Numero pedido</Form.Label>
                <Form.Control 
                    value={state.numero} 
                    onChange={ e => {
                        e.preventDefault()
                        setPedido({numero: e.target.value}) 
                    }}

                    name="numero"
                    isInvalid={errors.numero}
                    ref={register({required:true})}
                    >
                </Form.Control>
            </Form.Group>
            <Form.Group>
                <Form.Label>Status do pedido</Form.Label>
                <Form.Control 
                    as="select"
                    value={state.status} 
                    onChange={ e => {
                        e.preventDefault()
                        setPedido({status: e.target.value}) 
                    }}

                    name="status"
                    isInvalid={errors.status}
                    ref={register({required:true})}
                    >
                        <option value="ARTE_CRIANDO">Criando Arte</option>
                        <option value="ARTE_APROVACAO">Arte em aprovação</option>
                        <option value="IMPRIMINDO">Imprimindo</option>
                        <option value="COLETADO">Coletado</option>
                        <option value="ENTREGUE">Entregue</option>
                </Form.Control>
            </Form.Group>
            <Form.Group>
                <Form.Label>Código de rastreio</Form.Label>
                <Form.Control 
                    value={state.codigo_rastreio} 
                    onChange={ e => {
                        e.preventDefault()
                        setPedido({codigo_rastreio: e.target.value}) 
                    }}

                    name="codigo_rastreio"
                    >
                </Form.Control>
            </Form.Group> 
            <Form.Group>
                <Form.Label>Previsão de entrega</Form.Label>
                <Form.Control 
                    value={state.previsao_entrega} 
                    onChange={ e => {
                        e.preventDefault()
                        setPedido({previsao_entrega: e.target.value}) 
                    }}

                    name="previsao_entrega"
                    >
                </Form.Control>
            </Form.Group> 
            <Form.Group>
                <Form.Label>Data de entrega</Form.Label><br/>
                <DatePicker 
                    dateFormat="dd/MM/yyyy"
                    className="form-control" 
                    selected={state.data_entrega} onChange={date => {
                    setPedido({data_entrega: date})
                }} />
            </Form.Group> 
            <Row className=" mt-2">
                <Col>
                     { props.drop ?
                     <Button variant="danger" onClick={props.onRemover}>
                        <Icon icon={faTrash}></Icon>
                     </Button>
                     : null }
                </Col>
                <Col className="text-right"><Button type="submit">Salvar</Button></Col>
            </Row>
        </Form>
    </>;
}

export default FormCategoria;