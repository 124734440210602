import React  from 'react';
import {
    // BrowserRouter,
    Switch,
    Route,
    HashRouter,
    Redirect,
  } from "react-router-dom";

import Login from './components/Login'
import Pedidos from './components/Pedidos'
import EditarPedido from './components/pedidos/Editar'
import NovoPedido from './components/pedidos/Novo'

export default () => {

    // const location = useLocation();

    return (
        <HashRouter>
            <Switch>
                    <Route exact path="/login">
                        <Login />
                    </Route>
                    <RouteProtected exact path="/">
                        <Pedidos />
                    </RouteProtected>
                    <RouteProtected exact path="/pedidos/novo">
                        <NovoPedido />
                    </RouteProtected>
                    <RouteProtected exact path="/pedidos/:pedido_id">
                        <EditarPedido />
                    </RouteProtected>
                </Switch>
        </HashRouter>
    );

    function RouteProtected({ children, ...rest }){
        
        return (
            <Route 
               {...rest}
               render={({ location }) => {
                    if(!localStorage.getItem('token')){
                        return <Redirect
                            to={{
                                pathname: '/login',
                                state: { from: location }
                            }}
                        />
                    }
                    return children
               }}/>
        )

        
    }

}