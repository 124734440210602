import React, {useState} from 'react'
import Form from './FormPedido'
import Header from '../Header'

import {$http} from '../../helpers'
import {loading,warning,success} from '../../helpers/alerts'
import { useHistory } from "react-router-dom";
import { format,isValid } from 'date-fns'

import {
    Card,
    Container
} from 'react-bootstrap'

const Editar = props => {

    const [state] = useState({
        loading: false
    })

    const [pedido,setPedido] = useState({
        cliente_nome: '',
        numero: '',
        status: 'ARTE_CRIANDO',
        previsao_entrega: '',
        data_entrega: '',
        codigo_rastreio: '',
    })

    let history = useHistory();

    const save = async (data) => {
        setPedido(data)

        try{

            loading('show') 

            let result = await $http.post(`pedidos`,{
                ...data,
                data_entrega: isValid(data.data_entrega) ? format(data.data_entrega,'yyyy-MM-dd') : null
            })

            if(result.status !== 201) throw result.data

            await success({
                title: 'Salvo com sucesso'
            })

            history.push('/')

        }catch(e){
            warning({
                title:'Houve alguma divergência',
                message: e
            })
        }
    }

    return (<>
        <Header />
        <Container style={{marginTop:10}}>
            <Card>
    <Card.Header>Nova pedido</Card.Header>
                <Card.Body>
                    {!state.loading ?
                    <Form data={pedido} onSave={save} />
                    :
                    'Carregando'
                    }
                </Card.Body>
            </Card>
        </Container>
    </>);
}

export default Editar;